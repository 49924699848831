.main_header_fixed,
.main_header {
    display         : block;
    width           : 100%;
    text-align      : center;
    font-family     : roboto;
    font-size       : 1.3em;
    text-transform  : uppercase;
    border-bottom   : 1px solid green;
    min-height      : 2em;
    background-color: #fAFF8a;
    text-decoration : none;
    color           : #333;
}

.main_header_fixed {
    position: absolute;
    z-index : 9998;
    top     : 0;
    left    : 0;
}

.logo {
    position: fixed;
    right   : .5em;
    top     : 0;
    z-index : 9999;
}

.logo img {
    height: 5em;
}

.play {
    display : flex;
    height  : 100vh;
    overflow: hidden;
}

.sliders_wrapper {
    flex       : 1;
    padding-top: 2.6em;
}


/* SLIDE TRANSITION */
.post_transition {
    display  : flex;
    height   : 100%;
    margin   : 0;
    padding  : 0;
    animation: fadeInTransition 2.5s forwards;
}

.post_transition img {
    flex           : 1;
    object-position: center;
    object-fit     : fill;
    overflow       : hidden;
    width          : 100%;
}

/* END SLIDE TRANSITION */


/* SLIDE IMG */
.post_images {
    height   : 100%;
    margin   : 0;
    padding  : 0;
    animation: fadeInContent 1s;
}

.post_images:before {
    content   : ' ';
    display   : block;
    position  : absolute;
    left      : 0;
    top       : 0;
    width     : 100%;
    height    : 100%;
    z-index   : 0;
    background-color: #222;
    /*
    opacity   : 0.13;
    background: #222 url("../../public/img/logo.png") repeat; 
    */
}

.post_images .carousel {
    padding: 0;
    margin : 0;
}

.post_images .carousel_inner {
    height : calc(100vh _ 4.9em);
    margin : 0;
    padding: 0;
}

.post_images .carousel_item {
    text-align: center;
    height    : 100%;
}

.post_images .carousel_item img {
    margin-top   : .2em;
    animation      : fadeInImage 1.5s;
    box-shadow     : 2px 2px 4px #333;
    height         : calc(100vh - 4.9em);
    flex           : 1;
    object-position: center;
    object-fit     : contain;
    overflow       : hidden;
    width          : 100%;
}

.post_images .image_title {
    display         : block;
    text-align      : center;
    width           : 100%;
    font-family     : roboto;
    font-size       : 1.3em;
    text-transform  : uppercase;
    margin          : 0;
    padding         : 0;
    border-top      : 1px solid green;
    min-height      : 1.5em;
    z-index         : 9998;
    background-color: #fAFF8a;
    position        : fixed;
    bottom          : 0;
    left            : 0;
}

/* END SLIDE IMG */

/*  SLIDE TEXT */
.post_text {
    width           : 100%;
    height          : 100%;
    margin          : 0;
    overflow        : auto;
    animation       : fadeInContent 2s;
    background-color: whitesmoke;
}

.current_scroll {
    position: fixed;
    top     : 0;
    right   : 1.5em;
}

.content_text {
    padding    : .5em;
    font-family: roboto;
    font-size  : 2.5em;
    text-align : justify;
    z-index    : 550;
}

.content_text_for_web {
    padding    : .5em;
    font-family: roboto;
    font-size  : 1.5em;
    text-align : justify;
    z-index    : 550;
}

/* END SLIDE TEXT */

/* YOUTUBE */
.post_video {
    animation: fadeInContent 4s;
    height   : 100%;
}

.video_responsive {
    height: 100%;
}

.video_responsive div {
    height    : 100%;
    text-align: center;
    background: black;
}

.video_responsive iframe {
    height    : 100%;
    width     : 100%;
    text-align: center;
}

/* END YOUTUBE */

@keyframes fadeInTransition {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    75% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeInContent {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInImage {
    0% {
        opacity: .2;
    }

    100% {
        opacity: 1;
    }
}