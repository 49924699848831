.main-header {
    display         : block;
    width           : 100%;
    text-align      : center;
    font-family     : roboto;
    font-size       : 1.3em;
    text-transform  : uppercase;
    border-bottom   : 1px solid green;
    min-height      : 2em;
    z-index         : 9998;
    background-image: linear-gradient(to bottom right, #fAFF8a, #e9e112);
    text-decoration : none;
    color           : #333;
}

.logo {
    position: fixed;
    right   : .5em;
    top     : 0;
    z-index : 9999;
}

.logo img {
    height: 5em;
}

.thumbnailGallery span img {
    height: 4em;
}

.max-height-10 {
    max-height: 17em;
}