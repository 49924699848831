.sidebar_fixed {
    position: fixed;
    right   : .5em;
}

.topLeft {
    position: fixed;
    top     : 5em;
    left    : .5em;
}

.back_top {
    position  : fixed;
    bottom    : 1.5em;
    right     : 2em;
    font-size : 2.5em;
    color     : #fd6202;
    cursor    : pointer;
    transition: all .3s;
}

.back_top:hover {
    color: yellow;
}

.compartir_facebook {
    position  : fixed;
    bottom    : 1.5em;
    right     : .75em;
    z-index  : 99999;
    font-size: 2.5em;
    height: 1.4em;
}

.category_link {
    border-bottom: 1px solid #EEE;
    text-decoration: none;
    text-transform: uppercase;
    background-color: ghostwhite;
    display: block;
    padding: 0.2em;
    text-align: center;
    color: #333;
    cursor: pointer;
}

.category_link:hover {
    background-color: whitesmoke;
    color: #000;
}